import nxModule from 'nxModule';

import templateUrl from './watchlist.template.html';
import './watchlist.style.less';

class WatchlistView {
  constructor(http, $location) {
    this.http = http;
    this.$location = $location;
  }

  async $onInit() {
    this.watchlist = await this.http.get('/management/watchlist').toPromise();
  }

  goBack() {
    this.$location.path('/admin/compliance')
  }
}

nxModule.component('watchlistView', {
  templateUrl,
  controller: WatchlistView
});